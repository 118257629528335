export enum FoodCategory {
    SEASONING = "Seasoning",
    DAIRY = "Dairy",
    PROTEIN = "Protein",
    PRODUCE = "Produce",
    GRAIN = "Grain",
    MISC = "Misc",
}

export enum FoodName {
    GARLIC_CLOVE = "GARLIC_CLOVE",
    CARROT = "CARROT",
    RED_ONION = "RED_ONION",
    BASMATI_RICE = "BASMATI_RICE",
    VEGETABLE_STOCK_CONCENTRATE = "VEGETABLE_STOCK_CONCENTRATE",
    CHICKPEAS = "CHICKPEAS",
    CURRY_POWDER = "CURRY_POWDER",
    GRAPE_TOMATOES = "GRAPE_TOMATOES",
    PERSIAN_CUCUMBER = "PERSIAN_CUCUMBER",
    CILANTRO = "CILANTRO",
    LEMON = "LEMON",
    CHILI_PEPPER_FRESH = "CHILI_PEPPER_FRESH",
    YOGURT = "YOGURT",
    SOUR_CREAM = "SOUR_CREAM",
    JASMINE_RICE = "JASMINE_RICE",
    GREEN_BEANS = "GREEN_BEANS",
    LIME = "LIME",
    GROUND_TURKEY = "GROUND_TURKEY",
    SWEET_SOY_GLAZE = "SWEET_SOY_GLAZE",
    SWEET_THAI_CHILI_SAUCE = "SWEET_THAI_CHILI_SAUCE",
    CRISPY_FRIED_ONIONS = "CRISPY_FRIED_ONIONS",
    SWEET_POTATO = "SWEET_POTATO",
    BUTTON_MUSHROOMS = "BUTTON_MUSHROOMS",
    GARLIC_POWDER = "GARLIC_POWDER",
    MAYONNAISE = "MAYONNAISE",
    RICE_WINE_VINEGAR = "RICE_WINE_VINEGAR",
    COLESLAW_MIX = "COLESLAW_MIX",
    YELLOW_ONION = "YELLOW_ONION",
    JALAPENO = "JALAPENO",
    ZUCCHINI = "ZUCCHINI",
    TUNISIAN_SPICE_BLEND = "TUNISIAN_SPICE_BLEND",
    SLICED_ALMONDS = "SLICED_ALMONDS",
    DRIED_APRICOTS = "DRIED_APRICOTS",
    HOT_SAUCE = "HOT_SAUCE",
    ITALIAN_SEASONING = "ITALIAN_SEASONING",
    ROMA_TOMATOES = "ROMA_TOMATOES",
    ISRAELI_COUSCOUS = "ISRAELI_COUSCOUS",
    PARMESAN_CHEESE = "PARMESAN_CHEESE",
    BALSAMIC_GLAZE = "BALSAMIC_GLAZE",
    CHILI_FLAKES = "CHILI_FLAKES",
    MOZZARELLA_CHEESE = "MOZZARELLA_CHEESE",
    SHALLOT = "SHALLOT",
    DILL = "DILL",
    CHICKEN_STOCK_CONCENTRATE = "CHICKEN_STOCK_CONCENTRATE",
    CHICKEN_CUTLET = "CHICKEN_CUTLET",
    ZAATAR_SPICE_BLEND = "ZAATAR_SPICE_BLEND",
    FETA_CHEESE = "FETA_CHEESE",
    HUMMUS = "HUMMUS",
    THYME = "THYME",
    SCALLION = "SCALLION",
    SMOKED_PAPRIKA = "SMOKED_PAPRIKA",
    KALE = "KALE",
    GOLDEN_RAISINS = "GOLDEN_RAISINS",
    SHREDDED_RED_CABBAGE = "SHREDDED_RED_CABBAGE",
    FRESH_GINGER = "FRESH_GINGER",
    CREMINI_MUSHROOM = "CREMINI_MUSHROOM",
    BELL_PEPPER = "BELL_PEPPER",
    HOISIN_SAUCE = "HOISIN_SAUCE",
    SOY_SAUCE = "SOY_SAUCE",
    SRIRACHA = "SRIRACHA",
    KIWI = "KIWI",
    CHIPOTLE_POWDER = "CHIPOTLE_POWDER",
    CHICKEN_BREAST = "CHICKEN_BREAST",
    SOUTHWEST_SPICE_BLEND = "SOUTHWEST_SPICE_BLEND",
    ASPARAGUS = "ASPARAGUS",
    SHAWARMA_SPICE_BLEND = "SHAWARMA_SPICE_BLEND",
    SUN_DRIED_TOMATOES = "SUN_DRIED_TOMATOES",
    SPAGHETTI = "SPAGHETTI",
    CHIVES = "CHIVES",
    ITALIAN_PARSLEY = "ITALIAN_PARSLEY",
    PENNE = "PENNE",
    COCONUT_MILK = "COCONUT_MILK",
    PANKO_BREADCRUMBS = "PANKO_BREADCRUMBS",
    GNOCCHI = "GNOCCHI",
    TRUFFLE_ZEST = "TRUFFLE_ZEST",
}

export enum Unit {
    CUP = "CUP",
    OZ = "OZ",
    INTEGER = "INTEGER",
    TBSP = "TBSP",
    TSP = "TSP", // 3 TSP to 1 TBSP,
    GRAM = "GRAM",
}

export interface Recipe {
    name: string;
    ingredients: Ingredient[];
    directions?: string;
}

interface Food {
    name: FoodName;
    category: FoodCategory;
}

export interface Ingredient {
    food: Food;
    quantity: number;
    unit: Unit;
}
